import { IInviteLinkContext } from './interfaces';
import { useControllerData } from '../main/controllerContext';
import React from 'react';

export function useInviteLink(): IInviteLinkContext {
  const props = useControllerData();
  return {
    goToInviteLink: props.goToInviteLink,
    setInviteLink: props.setInviteLink,
    inviteLink: props.inviteLink,
  };
}

export const withInviteLink = <Props = any,>(
  Component: React.ComponentType<Props>,
) => {
  return (props: Props) => {
    const inviteLinkProps = useInviteLink();
    return <Component {...props} {...inviteLinkProps} />;
  };
};
